export const appendParams = (path: string, params: Record<string, any>) => {
  const hasBase = /^https?:\/\//i.test(path);
  const urlObj = new URL(path, hasBase ? undefined : 'http://dummy-base');

  Object.keys(params).forEach(key => {
    const value = params[key];

    if (value !== undefined && value !== null && value !== '') {
      urlObj.searchParams.append(key, String(value));
    }
  });

  return hasBase ? urlObj.toString() : urlObj.pathname + urlObj.search;
};
