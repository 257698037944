import { useMatch } from 'react-router-dom';
import useSWR from 'swr';

import server from '@/services/server';
import type { User } from '@/types/User';

const useAuth = () => {
  const dashboardUserTool = useMatch({ path: '/next/users/*' });

  const { data, error, isLoading, mutate } = useSWR(
    'user',
    async (path: string): Promise<User> =>
      server.get({
        path,
        queryParams: { tool: dashboardUserTool ? 'dashboard_user' : '' }
      })
  );

  return {
    error,
    isLoading,
    mutate,
    user: data?.data
  };
};

export default useAuth;
